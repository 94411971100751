.posts {
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2em;
}

.post {
    position: relative;
    width: 29%;
    margin: 2em 1% 2em 1%;
    max-height: 350px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 1em;
    text-align: left;
    
}

.darken {
    z-index:1;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.popup, .comments {
    z-index: 2;
    position: fixed;
    width: 65%;
    min-height: 50%;
    left: 17.5%;
    top: 20%;
    background-color: white;
    border-radius: 10px;
}

.comments {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.comments .post {
    position: relative;
    width: 40%;
    margin: 2em 1% 2em 1%;
    max-height: 350px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 1em;
    text-align: left;
}

.comments-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
    width: 40%;
    margin: 2em 1% 2em 1%;
    text-align: left;
}

.comments-section{
    overflow: auto;
}

.vertical-line{
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    height: 300px;
}

.new-title,.new-username,.new-content, .new-image {
    margin-top: 1em;
    width: 75%;
    line-height: 32px;
}

button:hover {
    cursor:pointer;
}

.fa-arrow-up:hover {
    cursor: pointer;
    color: lightgreen;
}

.fa-comment:hover {
    cursor: pointer;
    color: black;
}