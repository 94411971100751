.logo {
    display: flex;
    height: 100%;
}
.logo div{
    display: inline-block;
    line-height: 5em;
}
.navbar {
    width: 85%;
    height: 5em;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 5% 1em 5%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    font-weight: bold;
}
.cloud {
    transform: scale(0.7);
}
.new-post {
    display:inline-block;
    width: 7em;
    height: 2em;
    line-height:2em;
    color: white;
    background-color: rgba(235, 154, 16, 0.8);
    border-radius: 10px;
}

.new-post:hover {
    cursor: pointer;
}

.header {
    font-size: 48px;
    font-weight: bold;
    margin-top: 0.5em;
}

.sub-header {
    font-size: 24px;
    color: rgba(0,0,0,0.5)
}